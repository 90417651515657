<template>
  <TemplateBlock
    mod-class
    content-class="shop-order-payment"
    title-page="Интернет-магазин"
  >
    <div class="w-100 h-100 shop-page">
      <ShopHeader />
      <div
        class="page__title mb-3 px-3 py-3 d-flex justify-content-start align-items-center"
      >
        <div class="text">Оплата заказа</div>
      </div>
      <div
        class="page__content container d-flex align-items-start justify-content-between"
      >
        <div v-if="!isContentLoading" class="mr-3 box w-100 payment">
          <div class="payment__header font-bold text-lg mb-4">
            Способы оплаты
          </div>
          <div class="payment__body">
            <div class="d-flex align-items-center justify-content-between">
              <button class="btn btn-second" @click="showLegalFaceModal">
                Выставление счета юр. лицу
              </button>
              <button class="btn btn-light" @click="goToShop">
                Вернуться к покупкам
              </button>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3">
              <button class="btn btn-second">Оплата картой физ.лица</button>
            </div>
          </div>
        </div>
        <div v-else>
          <b-spinner></b-spinner>
        </div>
        <div v-if="productsWithFullInfo" class="page__info-sum box ml-3">
          <ShopCartInfoSum
            :products="products"
            :summ="totalSumm"
            :discount="totalDiscount"
            :discountPercent="5"
            :hasOrder="true"
          />
        </div>
      </div>
    </div>

    <LegalFaceFormModal
      v-if="isLegalFaceModalVisible"
      @close="hideLegalFaceModal"
      @submit-data="doSubmitLegalFaceData"
    />
  </TemplateBlock>
</template>

<script>
import RequestManager from "@/function/request/RequestManager";
import ShopCartInfoSum from "@/components/Shop/ShopCartInfoSum.vue";
import TemplateBlock from "@/components/TepmplateBlock.vue";
import ShopHeader from "@/components/Shop/ShopHeader.vue";
import LegalFaceFormModal from "@/components/Shop/LegalFaceFormModal.vue";

export default {
  name: "ShopOrderPayment",
  components: {
    TemplateBlock,
    ShopHeader,
    ShopCartInfoSum,
    LegalFaceFormModal
  },
  data() {
    return {
      isContentLoading: false,
      products: [],
      productsWithFullInfo: [],
      totalSumm: 0,
      isLegalFaceModalVisible: false,
      isOrderSubmitting: false,
      currentUser: undefined
    };
  },
  computed: {
    totalDiscount() {
      if (this.currentUser && this.currentUser.id) {
        return this.totalSumm * 0.05;
      }
      return 0;
    }
  },
  methods: {
    calculateTotalSumm() {
      let summ = 0;
      if (this.products && this.products.length) {
        this.products.forEach(product => {
          const foundProductInfo = this.productsWithFullInfo.find(
            prd => prd.id === product.product_id
          );
          if (foundProductInfo) {
            summ = summ + foundProductInfo.price * product.count;
          }
        });
      }
      this.totalSumm = summ;
    },
    goToShop() {
      this.$router.push("/shop");
    },
    showLegalFaceModal() {
      this.isLegalFaceModalVisible = true;
    },
    hideLegalFaceModal() {
      this.isLegalFaceModalVisible = false;
    },
    doSubmitLegalFaceData(formData) {
      const structuredFormData = {
        email: formData.email || this.currentUser?.email || "",
        clientInfo: {
          Наименование: formData.name,
          ИНН: formData.inn,
          КПП: formData.kpp,
          ОГРН: formData.ogrn,
          "Юридический адрес": formData.legalAddress,
          "Фактический адрес": formData.factAddress,
          "Название банка": formData.bank_name,
          "Номер счета": formData.bank_account_num,
          "БИК банка": formData.bank_bik,
          "Корсчет банка": formData.bank_ks
        },
        positions: []
      };
      this.products.forEach(product => {
        structuredFormData.positions.push({
          productId: product.product_id,
          count: product.count
        });
      });
      this.isOrderSubmitting = true;
      RequestManager()
        .shopOrderApi.createOrder(structuredFormData)
        .then(response => {
          this.hideLegalFaceModal();
          localStorage.setItem("cart_products", "");
          if (this.currentUser?.id) {
            this.$router.replace({
              name: "Order",
              params: {
                id: response.systemOrderId
              }
            });
          } else {
            this.$router.replace({ name: "Shop" });
          }
        })
        .finally(() => (this.isOrderSubmitting = false));
    }
  },
  created() {
    const currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser")) || {};
    }
  },
  async mounted() {
    const prodSource = localStorage.getItem("cart_products");
    if (prodSource) {
      this.products = JSON.parse(prodSource);
      const productsIds = this.products.map(product => product.product_id);
      if (productsIds.length) {
        let { items } = await RequestManager().productApi.listProduct({
          ids: productsIds
        });
        items.forEach(product => (product.technique = {}));
        const techniqueIds = items.map(product => product.techniqueId);
        const techniqueItems = await RequestManager().techniqueApi.techniqueList(
          {
            ids: techniqueIds
          }
        );
        techniqueItems.items.forEach(tech => {
          const foundProduct = items.find(
            product => product.techniqueId === Number(tech.id)
          );
          if (foundProduct) {
            foundProduct.technique = tech;
          }
        });
        this.isContentLoading = false;
        this.$set(this, "productsWithFullInfo", items.splice(0));
        this.calculateTotalSumm();
      } else {
        this.isContentLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page__title {
  font-weight: 600;

  .text {
    font-size: 24px;
  }
}

.box {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px 15px;
}

.pseudo-link {
  cursor: pointer;
  color: #006b90;
  font-weight: 500;
}
.pseudo-link:hover {
  text-decoration: underline;
}
.font-bold {
  font-weight: 600;
}
.text-lg {
  font-size: 1.2em;
}

.shop-page {
  background-color: #f9f9ff;
}

.btn {
  padding: 1em !important;
}

.btn-light {
  color: #006b90;
  border: 1px solid rgba(0, 107, 144, 0.7);
  background-color: transparent;
}
.btn-light:hover {
  color: #ffffff;
  background-color: rgba(0, 107, 144, 0.7);
}
</style>
