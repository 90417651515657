<template>
  <section class="wrapper">
    <div class="status-block">
      <div class="d-flex justify-content-between">
        <h5 class="mb-3 ml-1">Данные для выставления счета</h5>
        <div class="c-pointer font-weight-bold" @click="close">x</div>
      </div>
      <div class="scroll-container-form">
        <template v-if="!currentUser || !currentUser.id">
          <Input v-model="formData.inn" label="ИНН" :required="true" />
          <Input v-model="formData.email" label="Email" :required="true" />
          <div class="form-group">
            <label class="form-group__label form-group_other required-label"
              >Телефон</label
            >
            <input
              type="text"
              class="form-control"
              v-phone
              v-model="formData.phone"
            />
          </div>
        </template>
        <template v-else>
          <div class="font-semibold">
            Счет будет выставлен со следующими данными:
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">Email:</div>
            <div class="font_semibold ml-2">{{ formData.email || "-" }}</div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">Телефон:</div>
            <div class="font_semibold ml-2">{{ formData.phone || "-" }}</div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">Наименование:</div>
            <div class="font_semibold ml-2">{{ formData.name || "-" }}</div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">ИНН:</div>
            <div class="font_semibold ml-2">{{ formData.inn || "-" }}</div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">КПП:</div>
            <div class="font_semibold ml-2">{{ formData.kpp || "-" }}</div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">ОГРН:</div>
            <div class="font_semibold ml-2">{{ formData.ogrn || "-" }}</div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">Юридический адрес:</div>
            <div class="font_semibold ml-2">
              {{ formData.legalAddress || "-" }}
            </div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">Фактический адрес:</div>
            <div class="font_semibold ml-2">
              {{ formData.factAddress || "-" }}
            </div>
          </div>
          <div class="d-flex mt-3">
            <div class="text-muted lbl">Название банка:</div>
            <div class="font_semibold ml-2">
              {{ formData.bank_name || "-" }}
            </div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">Номер счета:</div>
            <div class="font_semibold ml-2">
              {{ formData.bank_account_num || "-" }}
            </div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">БИК банка:</div>
            <div class="font_semibold ml-2">{{ formData.bank_bik || "-" }}</div>
          </div>
          <div class="d-flex mt-1">
            <div class="text-muted lbl">Корсчет банка:</div>
            <div class="font_semibold ml-2">{{ formData.bank_ks || "-" }}</div>
          </div>

          <div class="mt-3 text-center">
            <div>Если Вы заметили ошибку в данных, свяжитесь с</div>
            <div>
              <router-link to="/chats" class="pseudo-link blue ml-1">
                Персональным менеджером
              </router-link>
            </div>
          </div>
        </template>

        <div class="mt-4 d-flex align-items-center justify-content-between">
          <button class="btn btn-light mr-2" @click="close">Назад</button>
          <button
            class="btn btn-second ml-2"
            :disabled="!canSendData"
            @click="doSubmitFormData"
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Input from "@/components/Form/FormElement/Input";
import RequestManager from "@/function/request/RequestManager";

const emptyFormData = {
  email: "",
  phone: "",
  name: "",
  inn: "",
  kpp: "",
  ogrn: "",
  legalAddress: "",
  factAddress: "",
  bank_name: "",
  bank_account_num: "",
  bank_bik: "",
  bank_ks: ""
};

export default {
  name: "LegalFaceFormModal",
  components: {
    Input
  },
  data() {
    return {
      isDataSubmitting: false,
      currentUser: {},
      formData: Object.assign({}, emptyFormData),
      companyInfo: undefined,
      isCompanyInfoLoading: false,
      bankData: undefined
    };
  },
  computed: {
    isEmailValid() {
      if (!this.formData.email || !this.formData.email.length) {
        return false;
      }
      return this.formData.email.toLowerCase().match(/\S+@\S+\.\S+/);
    },
    canSendData() {
      return this.currentUser?.id
        ? !!this.formData.email?.length && !this.isCompanyInfoLoading
        : this.isEmailValid &&
            !!this.formData.phone?.length &&
            !!this.formData.inn?.length &&
            !this.isCompanyInfoLoading;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    doSubmitFormData() {
      this.$emit("submit-data", this.formData);
    }
  },
  created() {
    const currentUserSource = localStorage.getItem("currentUser");
    if (currentUserSource) {
      this.currentUser = JSON.parse(currentUserSource);
    }
  },
  async mounted() {
    if (this.currentUser?.id) {
      this.isCompanyInfoLoading = true;
      this.companyInfo = await RequestManager().companyApi.getCompany(
        this.currentUser.company
      );
      this.formData.email = this.currentUser.email;
      this.formData.phone = this.currentUser.phone;
      this.formData.inn = this.companyInfo.inn;
      RequestManager()
        .companyApi.getData(this.companyInfo.inn)
        .then(response => {
          this.formData.name = response.brand;
          this.formData.kpp = response.kpp;
          this.formData.ogrn = response.ogrn;
          this.formData.legalAddress = response.legalEntityAddress;
          this.formData.factAddress = this.companyInfo.factAddress;
          this.formData.bank_name = response.bankName;
          this.formData.bank_account_num = response.bankAccountNum;
          this.formData.bank_bik = response.bankBik;
          this.formData.bank_ks = response.bankKs;
        })
        .finally(() => (this.isCompanyInfoLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
  overflow: auto;
}

.status-block {
  background: var(--main-card-color);
  border-radius: 6px;
  padding: 20px;
  width: 640px;
  margin: auto auto;
  transform: translateY(3%);
}

.btn {
  padding: 1em !important;
}

.btn:disabled:hover {
  background-color: rgba(201, 201, 201, 0.7) !important;
  cursor: not-allowed;
}

.btn-light {
  color: #006b90;
  border: 1px solid rgba(0, 107, 144, 0.7);
  background-color: transparent;
}
.btn-light:hover {
  color: #ffffff;
  background-color: rgba(0, 107, 144, 0.7);
}

.font_semibold {
  font-weight: 600;
}

.lbl {
  min-width: 170px;
  width: 170px !important;
  white-space: nowrap;
}

.form-group__label {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--main-text-color);
  position: relative;
}

.form-control {
  background: var(--auth-input-bg);
  border: 1px solid var(--auth-input-bg);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
  color: var(--main-text-color);
}

.required-label:after {
  content: "*";
  font-size: 26px;
  color: red;
  position: absolute;
}
</style>
